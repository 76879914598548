import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';
import { Card, Image, Layout, Metadata } from '../../components';
import { media, color } from '../../theme';
import { vr } from '../../utils';

const newsQuery = graphql`
  query NewsPage{
    allMdx(sort: {fields: [frontmatter___date], order: DESC}, filter: {frontmatter: {templateKey: {eq: "news-post"}, date: {gt: "2020-01-01"}}}) {
      edges {
        node {
          id
          fields {
            slug
          }
          excerpt
          frontmatter {
            date(formatString: "Do MMMM YYYY", locale: "da")
            title
            image {
              childImageSharp {
                resize(width: 350, height: 180) {
                  aspectRatio
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;

function Page({ data }) {
  const { edges: posts } = data.allMdx;

  return (
    <Layout>
      <Metadata title="Læs de seneste nyheder" />
      <section>
        <div className="container">
          <h1>Nyheder</h1>
          <div style={{ maxWidth: '100%' }}>
            {posts.map((p) => {
              const post = p.node.frontmatter;

              return (
                <Article to={p.node.fields.slug}>
                  <div className="image" data-overlay="half">
                    <Image src={post.image} />
                  </div>
                  <div className="content">
                    <h2>{post.title}</h2>
                    <small>{post.date}</small>
                    <p>{p.node.excerpt}</p>
                  </div>
                </Article>
              );
            })}
          </div>
        </div>
      </section>
    </Layout>
  );
}

const Article = styled(Link)`
  margin: 0 0 ${vr(1)} 0;
  text-decoration: none;
  font-weight: 400;
  cursor: pointer;

  .image {
    width: 100%;
    display: flex;
    height: auto;

    img {
      width: 100%;
    }
  }

  .content {
    padding: ${vr(0.75)} 0 ${vr(1.5)};
  }

  &:hover h2 {
    color: ${color.gold};
  }

  @media ${media.tabletL} {
    display: flex;
    flex-direction: row;

    .content {
      padding: ${vr(0.5)} ${vr(2.5)} ${vr(0.5)} ${vr(1)};
    }

    .image {
      max-width: 350px;
    }
  }
`;

const NewsIndexPage = () => (
  <StaticQuery
    query={newsQuery}
    render={(data, count) => <Page data={data} count={count} />}
  />
);

export default NewsIndexPage;
